
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { SeparatedList } from "@num/component-library";

import CDLogo from "@/components/CDLogo.vue";
import Container from "@/components/Container.vue";

import { STAGE } from "@/app.config";

export default Vue.extend({
  name: "CDFooter",
  components: {
    CDLogo,
    Container,
    SeparatedList,
  },
  data() {
    return {
      STAGE,
    };
  },
  computed: {
    ...mapGetters(["countryHomePath"]),
    ...mapState("session", ["user"]),
    years() {
      const yearNow = moment().format("YYYY");
      return yearNow === "2021" ? "2021" : `2021 &ndash; ${yearNow}`;
    },
  },
});
